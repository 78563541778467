import {IChartingLibraryWidget} from '@tradingviewtrader/charting_library';
import {ThemeName} from '@tradingview/charting_library';


export class StaticMethodsTvChart {

  public static createButtonTVChart(tv: IChartingLibraryWidget, align: 'left' | 'right',
                                    tooltip: string, caption: string,
                                    onClick: (event: Event) => void, classNameForCustomButton: string): HTMLElement {
    const button = tv.createButton({align: align, useTradingViewStyle: false, });
    button.setAttribute('title', tooltip);
    button.addEventListener('click', onClick);
    button.textContent = caption;
    button.setAttribute('class', classNameForCustomButton);
    return button;
  }

  public static checkingLanguageCode(language: string): string {
    switch (language) {
      case 'ar' :
      case 'zh' :
      case 'cs' :
      case 'en' :
      case 'fr' :
      case 'de' :
      case 'el' :
      case 'it' :
      case 'ja' :
      case 'ko' :
      case 'fa' :
      case 'pl' :
      case 'pt' :
      case 'ro' :
      case 'ru' :
      case 'es' :
      case 'sv' :
      case 'th' :
      case 'tr' :
      case 'vi' :
      case 'no' :
        return language;
      case 'sk' :
      case 'sk_SK':
        return 'sk_SK';
      case 'da' :
      case 'da_DK' :
        return  'da_DK';
      case 'ca' :
      case 'ca_ES' :
        return  'ca_ES';
      case 'nl' :
      case 'nl_NL' :
        return  'nl_NL';
      case  'ms' :
      case  'ms_MY' :
        return 'ms_MY';
      case  'tw' :
      case  'zh_TW' :
        return 'zh_TW';
      case 'he' :
      case 'he_IL' :
        return 'he_IL';
      case  'hu' :
      case  'hu_HU' :
        return 'hu_HU';
      case  'id' :
      case  'id_ID' :
        return 'id_ID';
      case 'et' :
      case 'et_EE' :
        return 'et_EE';
      default :
        return 'en';
    }
  }

  public static getTheme(): ThemeName {

    const activeTheme = localStorage.getItem('theme');
    if (activeTheme !== null) {
      if (activeTheme === 'tvZetradexDarkTheme' || activeTheme === 'tvDefaultTheme' || activeTheme === 'tvDarkTheme') {
        return 'dark';
      } else if (activeTheme === 'tvZetradexLightTheme' || activeTheme === 'tvLightTheme') {
        return 'light';
      }
    } else {
      return 'dark';
    }
  }

}
