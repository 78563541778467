import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {TranslatorService} from '@common/locale/servises/translator.service';
import {FormGroup, NgForm} from '@angular/forms';
import {LoginService} from '@common/auth/services/login.service';
import {ConnectionService} from '@common/communication/connection/connection.service';
import {CommandAnswerService} from '@common/communication/connection/command-answer-service';
import {OperationsEncryptDecryptData} from '@common/trade/utils/operations-encrypt-decrypt-data';
import {AppConfig} from '@common/configuration/app-config';
import {TerminalService} from '@common/trade/services/terminal.service';
import { Subscription } from 'rxjs';
import {DebugService} from '@common/shared/services/debug.service';
import {Environment} from '@common/environment';
import {ActivatedRoute} from '@angular/router';
import {UsersInSystemsService} from '@common/trade/services/users-in-systems.service';


@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {

  private subscribeSuccessEvent: Subscription;
  private showAnswerLoginSuccess = false;
  private textAnswerLoginSuccess = '';
  private _abortConnection = false;
  public hide = true;
  public staySignedIn = true;
  private currentUserInSystem: string;
  private _isShowSwitchRegionConnect = false;
  private signInform: FormGroup;
  public login = '';
  public password = '';

  @HostListener('document:keydown', ['$event'])
  private async onKeyboardKeyDown(event: KeyboardEvent) {
    if (event.key !== undefined) {
      if (event.key.toLowerCase() === 'enter') {
        event.preventDefault();

        await this.saveLocale();
      }
    }
  }

  public get isConnecting(): boolean {
    return this.loginService.isConnecting;
  }

  public get isReconnecting(): boolean {
    return this.loginService.isReconnecting;
  }

  public get LoginDisabled(): boolean {
    return this.loginService.LoginDisabled;
  }

  get isForgotPasswordLinkAvailable(): boolean {
    return this.loginService.isForgotPasswordLinkAvailable;
  }

  get IsSignInAsGuestAvailable(): boolean {
    return this.loginService.IsSignInAsGuestAvailable;
  }

  public get IsShowForgotPassword(): boolean {
    if (this.config.Settings.connection.ShowForgotPassword !== undefined) {
      return this.config.Settings.connection.ShowForgotPassword;
    }

    return true;
  }

  get ErrorText() {
    return this.loginService.ErrorText;
  }

  get ErrorConnectServer() {
    return this.connectionService.ErrorConnectServer;
  }

  constructor(private translator: TranslatorService,
              private loginService: LoginService,
              private connectionService: ConnectionService,
              private commandAnswerService: CommandAnswerService,
              private config: AppConfig,
              private guidPageService: TerminalService,
              private debugService: DebugService,
              private route: ActivatedRoute,
              private usersInSystemsService: UsersInSystemsService ) {
    if (this.config.Settings.connection.regionsToConnectTo) {
      this._isShowSwitchRegionConnect = true;
    }
  }


  async ngOnInit() {

    this.loginService.checkLoggedOutDueToInactivity();

    const token = this.route.snapshot.queryParamMap.get('Token');

    if (token !== null) {
      await this.loginService.initService(false);
      await this.loginService.tryLoginByTokenLink(token);
      sessionStorage.setItem('logInUsingLoginLink', 'true');
    } else {

      await this.loginService.initService();

      const pageState = JSON.parse(sessionStorage.getItem('pageReloadEvent'));
      const currentUser = JSON.parse(this.usersInSystemsService.getFromLocalStorage());

      if ( pageState !== null) {
        if (currentUser !== null && Array.isArray(currentUser)) {
          const index = currentUser.findIndex(el => el.user === pageState.traderName);
          if (index !== -1) {
            this.loginService.sendLoginDetails(currentUser[index].user, currentUser[index].password, false, currentUser[index].accessKey);
            this.seeLoginAndPasswordInForm(currentUser[index].user, currentUser[index].password);
          }
        }
      } else {
        if (currentUser !== null && Array.isArray(currentUser)) {
          if (currentUser[0].staySignedIn) {
            this.currentUserInSystem = currentUser[0].user;
            this.loginService.sendLoginDetails(currentUser[0].user, currentUser[0].password, false, currentUser[0].accessKey);
            this.seeLoginAndPasswordInForm(currentUser[0].user, currentUser[0].password);
          }
        }
      }

    }

    this.subscribeSuccessEvent = this.commandAnswerService.SuccessEvent.subscribe((value) => {
      if (!this.showAnswerLoginSuccess) {
        this.showAnswerLoginSuccess = true;
      }

      if (this.ErrorText.length > 0 || this.ErrorConnectServer.length > 0) {
        this.showAnswerLoginSuccess = false;
        this.loginService.clearUsersInSystem(this.currentUserInSystem);
      } else if (this.ErrorText.length === 0 && !this.showAnswerLoginSuccess) {
        this.showAnswerLoginSuccess = true;
      } else if (this.ErrorConnectServer.length === 0 && !this.showAnswerLoginSuccess) {
        this.showAnswerLoginSuccess = true;
      }

      if (this.showAnswerLoginSuccess && this._abortConnection ) {
        this.showAnswerLoginSuccess = false;
      }
      this.setTextAnswerLoginSuccess(value);

      console.log(value);
    });

    const currentPasswordIncorrectly  = sessionStorage.getItem('CurrentPasswordIncorrectly');

    if (currentPasswordIncorrectly !== null) {
      this.loginService.ErrorText = this.translator.getLocalPhrase('AuthModule_SignInComponent_WrongPasswordWasEntered');
      sessionStorage.removeItem('CurrentPasswordIncorrectly');
      setTimeout(() => {
        this.loginService.ErrorText = '';
      }, 3000);
    }
  }

  public async saveLocale() {
    this.translator.saveLocale();
  }

  loginAsGuest() {
    this.loginService.loginAsGuest();
  }

  async tryLogin(form: NgForm) {
    this._abortConnection = false;
    this.connectionService.ErrorConnectServer = '';
    this.loginService.checkSignatureUserAgreement();
    const login = form.controls.login.value.trim();
    const password = form.controls.password.value.trim();
    if (this.debugService.IsOpenDebugMode(login)) {
      this.debugService.toggleHiddenBtnDebugMode();
      return;
    }

    this.currentUserInSystem = login;
    this.loginService.saveUserInSystem(login, password, this.staySignedIn);
    this.addLoginDetails();
    this.loginService.sendLoginDetails(login, password);
    sessionStorage.removeItem('IsLogOut');
  }

  private seeLoginAndPasswordInForm(login: string, password: string|undefined): void {
    this.login = login ? login : '';
    if (password) {
      this.password = OperationsEncryptDecryptData.decryptText(password);
    }
  }

  async onForgotPassword($event: MouseEvent) {
    await this.loginService.onForgotPassword($event);
  }

  abortConnection() {
    this.usersInSystemsService.removeFromLocalStorage();
    this._abortConnection = true;
    this.loginService.abortConnection();
    this.login = '';
    this.password = '';
  }

  ngOnDestroy(): void {
    this.loginService.destroyService();
    this.connectionService.ErrorConnectServer = '';
    this.loginService.ErrorText = '';
    this.subscribeSuccessEvent.unsubscribe();
    this.textAnswerLoginSuccess = '';
    this.showAnswerLoginSuccess = false;
    this.loginService.isAnswerLoginSuccessReceivedChange();
    sessionStorage.removeItem('pageReloadEvent');
  }

  public resetErrorText() {
    this.loginService.ErrorText = '';
  }

  isAnswerLoginSuccessReceived(): boolean {
    return this.loginService.isAnswerLoginSuccessReceived();
  }

  isTraderInfoLoaded(): boolean {
    return this.loginService.isTraderLoaded();
  }

  get isShowAnswerLoginSuccess() {
    return this.showAnswerLoginSuccess;
  }

  get ShowTextAnswerLoginSuccess() {
    return this.textAnswerLoginSuccess;
  }

  private setTextAnswerLoginSuccess(v: string) {
    switch (v) {
      case 'Answer_CustomSessionError': // вместо сессии об ошибках решил написать про то что получены данные пользователя
        this.textAnswerLoginSuccess = this.translator.getLocalPhrase('AuthModule_SignInComponent_UserDataReceived');
        break;
      case 'Answer_Version':
        this.textAnswerLoginSuccess = this.translator.getLocalPhrase('AuthModule_SignInComponent_ApplicationVersionReceived');
        break;
      case 'Answer_ServerOption':
        this.textAnswerLoginSuccess = this.translator.getLocalPhrase('AuthModule_SignInComponent_ServerSettingsReceived');
        break;
      case 'Answer_LoginSuccess':
        this.textAnswerLoginSuccess = this.translator.getLocalPhrase('AuthModule_SignInComponent_LoginIsAllowed');
        break;
    }
  }

  private addLoginDetails() {
    this.guidPageService.addLoginDetails();
  }

  public isZeTradex() {
    if (this.config.Settings.common.AppName === 'Zetradex') {
      return true;
    }
    return false;
  }

  public get IsMobile(): boolean {
    return Environment.IsMobileVersion;
  }

  public  getVisibilityIcon() {
    if ( !this.hide ) {
      return 'z-visibility';
    } else {
      return 'z-visibility-off';
    }
  }

  public changeStateCheckbox() {
    this.staySignedIn = !this.staySignedIn;
  }

  public get IsShowSwitchRegionConnect(): boolean {
    return this._isShowSwitchRegionConnect;
  }
}

