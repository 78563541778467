import {EventEmitter, Injectable} from '@angular/core';
import {ServerInteractionService} from '@common/communication/connection/server-interaction.service';
import {Answer_PingPong, Command_PingPong} from '@common/communication/connection/classes.g';
import {ConnectionService} from '@common/communication/connection/connection.service';
import {AppConfig} from '@common/configuration/app-config';
import { Subscription } from 'rxjs';
import {MessageProcessor} from '@common/communication/message-processor/message-processor';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {Symbol} from '@common/symbol/models/symbol';
import {SymbolService} from '@common/symbol/services/symbol.service';
import {TradingViewItemHistoryConnectionService} from '@common/trading-view-charts/services/trading-view-item-history-connection.service';
import {DataLoaderService} from '@common/shared/services/data-loader.service';

@Injectable({
  providedIn: 'root'
})

export class CheckingLifeOfSessionService {
  get ShowNotificationCheckConnection(): boolean {
    return this._showNotificationCheckConnection;
  }


  private subscribePingPong: Subscription;
  private subscribeReconnectEvent: Subscription;
  private resetLastPriceEvent: Subscription;
  public stateConnectionServerEvent: EventEmitter<string> = new EventEmitter<string>();
  private readonly pinPongService: PinPongService;
  private readonly _connection: ConnectionService;
  private eventListenerCreate = false;
  private _showNotificationCheckConnection = false;
  private sessionLive = true;
  private checkSessionLivTimer;
  private pingResponseWaitingTime = 5000;


  public constructor(protected serverConnection: ServerInteractionService,
                     private messageProcessor: MessageProcessor,
                     private symbolStorage: SymbolStorageService,
                     private symbolService: SymbolService,
                     private tradingViewItemHistoryConnectionService: TradingViewItemHistoryConnectionService,
                     private dataLoaderService: DataLoaderService,
                     private appConfig: AppConfig,
                     connection: ConnectionService) {
    this.pinPongService = new PinPongService(this.serverConnection);
    this._connection = connection;

    if (this.appConfig.Settings.pingResponseWaitingTime !== undefined) {
      this.pingResponseWaitingTime = this.appConfig.Settings.pingResponseWaitingTime;
    }
  }

  private actionEventListener(window: Window & typeof globalThis) {
    const pinPongService = this.pinPongService;
    let state = getState();
    let connection = true;

    function getState() {
      if (window.document.visibilityState === 'hidden') {
        return 'hidden';
      }
      if (window.document.hasFocus()) {
        return 'focused';
      }
      return 'not focused';
    }

    function logStateChange(nextState: string) {
      const prevState = state;
      if (nextState !== prevState) {
        state = nextState;
        setTimeout(() => {
          if (nextState === 'focused' && connection) {
            if (sessionStorage.getItem('IsLogOut') == null) {
              pinPongService.pingPong();
            }
          }

        }, 500);
      }
    }

    function onPageStateChange() {
      if (sessionStorage.getItem('IsLogOut') != null) {
        console.log('removeEventListeners');
        removeEventListeners();
      } else {
        logStateChange(getState());
      }
    }

    function onFreeze() {
      logStateChange('frozen');
    }

    function onPageHide(event) {
      if (event.persisted) {
        logStateChange('frozen');
      } else {
        logStateChange('terminated');
      }
    }

    function lossConnection() {
      connection = false;
    }

    function connectionRestored() {
      connection = true;
      pinPongService.pingPong();
    }

    function removeEventListeners() {
      ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(function (type) {
        window.removeEventListener(type, onPageStateChange.bind(this), {capture: true});
      });
      window.removeEventListener('pagehide', onPageHide.bind(this), {capture: true});
      window.removeEventListener('freeze', onFreeze.bind(this), {capture: true});
      window.removeEventListener('online', connectionRestored.bind(this), {capture: true});
      window.removeEventListener('offline', lossConnection.bind(this), {capture: true});
    }

    if (!this.eventListenerCreate) {
      ['pageshow', 'focus', 'blur', 'visibilitychange', 'resume'].forEach(function (type) {
        window.addEventListener(type, onPageStateChange.bind(this), {capture: true});
      });
      window.addEventListener('pagehide', onPageHide.bind(this), {capture: true});
      window.addEventListener('freeze', onFreeze.bind(this), {capture: true});
      window.addEventListener('online', connectionRestored.bind(this), {capture: true});
      window.addEventListener('offline', lossConnection.bind(this), {capture: true});
      this.eventListenerCreate = true;
    }
  }

  public logout() {
    sessionStorage.setItem('IsLogOut', 'true');

    if (this.subscribePingPong) {
      this.subscribePingPong.unsubscribe();
      this.subscribePingPong = undefined;
    }

    if (this.resetLastPriceEvent) {
      this.resetLastPriceEvent.unsubscribe();
      this.resetLastPriceEvent = undefined;
    }

    if (this.subscribeReconnectEvent) {
      this.subscribeReconnectEvent.unsubscribe();
      this.subscribeReconnectEvent = undefined;
    }
  }

  public run() {
    this.subscribeAnswerPingPong();
    this.actionEventListener(window);

    if (this.subscribePingPong === undefined) {
      this.subscribePingPong = this.pinPongService.SendCommandSubscribe.subscribe(() => {
        this.sessionLive = false;

        this.checkSessionLivTimer = setTimeout(() => {
          this.checkSessionLive();
        }, this.pingResponseWaitingTime);

        setTimeout(() => {
          if (!this.sessionLive) {
            this._showNotificationCheckConnection = true;
          }
        }, 1000);

      });
    }

    if (this.resetLastPriceEvent === undefined) {
      this.resetLastPriceEvent = this._connection.ResetLastPriceEvent().subscribe(() => {
        this.resetLastPrice();
        this.reconnectItemHistory();
        this.dataLoaderService.loadTrades().then();
        this.stateConnectionServerEvent.emit('yes');
        console.log('Re-requesting data');
        console.log('-------------------');
      });
    }

    if (this.subscribeReconnectEvent === undefined) {
      this.subscribeReconnectEvent = this._connection.ReconnectEvent().subscribe(() => {
        this._showNotificationCheckConnection = true;
        this.silentReconnect('Reconnect');
        console.log('Reconnect');
        console.log('-------------------------');
      });
    }
  }

  public runCheckingLifeOfSessionInChart(iframe: HTMLIFrameElement) {
    console.log('iframeBody', 23, iframe);
    this.actionEventListener(iframe.contentWindow as Window & typeof globalThis);
    // iframe.contentWindow.addEventListener('mousemove', () => { console.log(234); }, {capture: true});
  }

  private subscribeAnswerPingPong(): void {
    this.serverConnection.on(Answer_PingPong.GetMessageType(),
      (answer: any) => {
        clearTimeout(this.checkSessionLivTimer);
        console.log(answer);
        this._showNotificationCheckConnection = false;
        this.sessionLive = true;
      });
  }

  private checkSessionLive() {
    if (!this.sessionLive) {
      console.log('Wake!!');
      this.silentReconnect('Ping Pong didn\'t answer');
    }
  }

  private silentReconnect(msg: string): void {
    this._connection.sendSaveErrors(msg).then();
    this._connection.disconnect(true, 'SilentReconnect');
    this.stateConnectionServerEvent.emit('no');
    if (!this.tradingViewItemHistoryConnectionService.IsUseNewItemHistoryConnect) {
      this.tradingViewItemHistoryConnectionService.stopConnect();
    }
    this.resetSubscribe();
    setTimeout(() => {
      this._showNotificationCheckConnection = false;
      this._connection.silentReconnect().then();
    }, 1000);
  }


  private reconnectItemHistory() {
    if (!this.tradingViewItemHistoryConnectionService.IsUseNewItemHistoryConnect) {
      this.tradingViewItemHistoryConnectionService.run().then();
    }
  }

  private resetSubscribe() {
    this.messageProcessor.unsubscribe();
    this.messageProcessor.subscribe();
  }

  private resetLastPrice(): void {
    const symbols: Symbol[] = this.symbolStorage.Symbols;
    symbols.forEach((item: Symbol) => {
      item.ResetLastQuote();
    });

    symbols.forEach((item: Symbol) => {
      this.symbolService.subscribeToSymbol(item.SymbolId).then();
    });
  }

  public StateConnectionServerEvent() {
    return this.stateConnectionServerEvent;
  }

}

class PinPongService {

  protected serverConnection: ServerInteractionService;
  private _sendCommandEmitter: EventEmitter<void> = new EventEmitter<void>();

  public constructor(serverConnection: ServerInteractionService) {
    this.serverConnection = serverConnection;
  }

  public get SendCommandSubscribe(): EventEmitter<void> {
    return this._sendCommandEmitter;
  }

  public pingPong() {
    const command = new Command_PingPong();
    this._sendCommandEmitter.emit();
    this.serverConnection.sendCommand(command);
  }

}
