import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ValueAccessorBase} from '@common/shared/components/value-accessor-base';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
  selector: 'selectable-input',
  templateUrl: './selectable-input.component.html',
  styleUrls: ['./selectable-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectableInputComponent),
    multi: true
  }]
})
export class SelectableInputComponent extends ValueAccessorBase<number> implements OnInit, OnDestroy {
  @Input()
  from = undefined;
  @Input()
  to = undefined;
  @Input()
  step = 1;
  @Input()
  length = 9;

  @Input()
  public set filler(v: string) {
    this._filler = v;
    this.fillInput();
  }
  public get filler(): string {
    return this._filler;
  }

  private _filler: string;

  @Output()
  select: Observable<number>;

  private selectEvent = new EventEmitter<number>();

  private inputValue: string;

  public opened: boolean;
  public list: any[];

  constructor() {
    super();

    this.select = this.selectEvent.asObservable();
  }

  ngOnInit() {
    this.fillInput();

    this.setValue(0);
  }

  ngOnDestroy(): void {
  }

  // метотод возвращает тип для инпута 'Ask' или 'Bid'
  public placeholder() {
    return this.inputValue;
  }

  public get Value(): string {
    return this.inputValue;
  }
  public set Value(v: string) {
    if (/[^0-9\-]/.test(v)) {
      this.value = 0;
      return;
    }
    const value = Number(v);
    this.writeValue(value);
  }

  public onSelect(v: number) {
    this.writeValue(v);
    this.selectEvent.emit(v);
    this.close();
  }

  public writeValue(value: number) {
    if (value == null) {
      value = 0;
    }

    super.writeValue(value);

    if (this.Touching) { return; }
    this.fillInput();
  }

  public onScrollUp() {
    this.list = [this.list[0] - 1].concat(this.list.slice(0, this.list.length - 1));
  }

  public onScrollDown() {
    this.list = this.list.slice(1).concat([this.list[this.list.length - 1] + 1]);
  }

  touch() {
    this.open();
    this.inputValue = this.value.toString();
    super.touch();
  }

  blur(): void {
    super.blur();
    this.fillInput();
  }

  public open() {
    this.list = this.generateList();
    this.opened = true;
  }

  public close() {
    this.list = [];
    this.opened = false;
  }

  private fillInput() {
    this.inputValue = this.format(this.value);
  }

  public format(value: number) {
    if (value > 0) {
      return `${this.filler} + ${value}`;
    } else if (value < 0) {
      return `${this.filler} - ${-value}`;
    } else { return this.filler; }
  }

  private generateList() {
    const steps = this.generateSteps();

    const list = steps.map(value => this.value + value);

    return list;
  }

  private generateSteps(): Array<number> {
    const center = Math.trunc(this.length / 2);

    const steps = [];

    for (let i = -center; i <= center; i++) {
      steps.push(i);
    }

    return steps;
  }
}
