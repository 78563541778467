import {
  DatafeedConfiguration, IBasicDataFeed, PeriodParams, ResolveCallback,
  SubscribeBarsCallback, SymbolResolveExtension, LibrarySymbolInfo,
  ResolutionString, HistoryCallback, SearchSymbolsCallback, DatafeedErrorCallback
} from '@tradingview/charting_library';
import {SymbolStorageService} from '@common/symbol/services/symbol-storage.service';
import {BarAggregatorService} from '@common/charting/services/bar-aggregator.service';
import {ISmartSubscription} from '@common/shared/subscriptions/smart-emitter';
import {QuoteTick} from '@common/charting/models/quote-tick';
import {PriceDirection} from '@common/symbol/models/price-direction';
import {Quote} from '@common/symbol/models/quote';
import {ChartConfigurationService} from '@common/trading-view-charts/services/chart-configuration.service';
import {Injectable} from '@angular/core';
import {LoggerFactory} from '@common/common/utils/logging/logger-factory';
import {Timer} from '@common/common/utils/timer';
import {TradingViewNotificationService} from '@common/trading-view-charts/services/trading-view-notification.service';
import {AppConfig} from '@common/configuration/app-config';
import {Symbol} from '@common/symbol/models/symbol';
import {SymbolInfoService} from '@common/shared/services/symbol-info.service';
import { SymbolInfo } from '@common/symbol/models/symbol-info';
import {TimeService} from '@common/shared/services/Time/time.service';
import {translate} from '@common/locale/servises/translator.service';

@Injectable({
  providedIn: 'root'
})
export class TradingViewDatafeed implements IBasicDataFeed {
  private subscriptionsMap = new Map<string, ISmartSubscription>();
  private onReset: () => void;
  protected priceDirection: PriceDirection;
  private logger = LoggerFactory.getLogger('TradingViewDatafeed');
  private oldSymbol: SymbolInfo;
  private interval: number;

  public constructor(protected symbolStorage: SymbolStorageService,
                     protected barAggregator: BarAggregatorService,
                     private symbolInfoService: SymbolInfoService,
                     protected configuration: ChartConfigurationService,
                     protected tradingViewNotificationService: TradingViewNotificationService,
                     protected appConfig: AppConfig,
                     private timeService: TimeService) {
    if (this.appConfig.Settings.charts !== undefined && this.appConfig.Settings.charts.PriceDirection !== undefined) {
      this.priceDirection = this.appConfig.Settings.charts.PriceDirection;
    } else {
      this.priceDirection = PriceDirection.Bid;
    }

    if (this.appConfig.Settings.filteringOutEmptyCandles !== undefined) {
      this.barAggregator.IsFilteringOutEmptyCandles = this.appConfig.Settings.filteringOutEmptyCandles;
    }
  }

  // calculateHistoryDepth(resolution: string, resolutionBack: 'D' | 'M', intervalBack: number): HistoryDepth | undefined {
  //    return undefined;
  // }

  getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, periodParams: PeriodParams, onResult: HistoryCallback, onError: DatafeedErrorCallback): void {

    let Resolution: string = resolution;
    if (resolution === '1D') {
      Resolution = 'D' as ResolutionString;
    } else if (resolution === '1W' ) {
      Resolution = 'W' as ResolutionString;
    } else if (resolution === '1M') {
      Resolution = 'M' as ResolutionString;
    }

    console.log(symbolInfo.name, Resolution, periodParams, this.priceDirection);

    const result = this.barAggregator.getBars(symbolInfo, Resolution as ResolutionString, periodParams, this.priceDirection);
    result.then(value => onResult(value.Bars, value.Metadata)).catch(e => onError(e.toString()));
  }

  // Возможно это и было решением этой задачи https://forexdevelopment.myjetbrains.com/youtrack/issue/CONDOR-265/Time-on-chart-is-wrong
  // закомментировал данный метод т.к. при первом входе время на часах графика отображается такое же, как и на часах футора,
  // но при дискотеке или пере заходе на страницу (без перезагрузки страницы) время отличалось

  getServerTime(callback: (serverTime: number) => void): void {
    this.timeService.getServerTime().then(() => {
      if (this.timeService.ServerTimeWithoutMilliseconds) {
        callback(this.timeService.ServerTimeWithoutMilliseconds);
      }
    });
  }

  async resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: DatafeedErrorCallback, extension?: SymbolResolveExtension): Promise<void> {
    try {
      const result = await this.symbolStorage.findSymbolByName(symbolName);
      this.logger.debug('resolve symbol');
      this.logger.debug('symbol resolved');
      onResolve(this.dataCollectionSymbolInfo(result) as LibrarySymbolInfo);
      this.oldSymbol = result.SymbolInfo;
    } catch ( e ) {
      this.logger.warn('symbol resolve error', e);
      if (this.oldSymbol !== undefined) {
        this.tradingViewNotificationService.showNotification();
        this.tradingViewNotificationService.symbolName = symbolName;
        // При загрузках любого пользователя в график передается первая валютная пара из массива доступных ему
        onResolve(this.symbolStorage.Symbols[0].SymbolInfo as LibrarySymbolInfo);
      } else {
        onResolve(this.symbolStorage.Symbols[0].SymbolInfo as LibrarySymbolInfo);
      }
      onError(e.message);
    }
  }

  private dataCollectionSymbolInfo(symbol: Symbol): LibrarySymbolInfo {
    const result = {
      ...symbol.SymbolInfo,
      min_fee_fee: `${symbol.MinTransactionFee} / ${symbol.TransactionFee.toFixed(2)} USD ${translate('ChartingModule_ChartStrings_perAMillionLeveragedMoney')}`,
      contract_size: symbol.ContractSize, // ? LOT show
      min_max_volume: `${symbol.MinVolume} / ${symbol.MaxVolume}`,
      trading_step: symbol.TradingStep,
      rollover_buy_sell: `${symbol.RolloverBuy} / ${symbol.RolloverSell}`,
      margin_rate: symbol.InitialMarginRate,
      pip_value: symbol.PipSize,
    };

    const symbolIconPath = this.symbolInfoService.getSymbolIcon(symbol);

    if (result.logo_urls === undefined && symbolIconPath !== undefined) {
      result.logo_urls = [symbolIconPath.replace(/^\./g, '')];
    }
    return result;
  }

  searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
    const result = this.symbolStorage.findSymbolsByPattern(userInput);

    result.then(value => {
      const temp = value.map(item => {
        if (item.SymbolInfo['logo_urls'] === undefined) {
          const pathSymbolIcon = this.symbolInfoService.getSymbolIcon(item);
          if (pathSymbolIcon !== undefined) {
            item.SymbolInfo['logo_urls'] = [pathSymbolIcon.replace(/^\./g, '') ];
          }
        }
        return  item.SymbolInfo;
      }).filter(item => (!exchange || item.exchange === exchange) && (!symbolType || item.type === symbolType));
      onResult(temp);
    });
  }

  subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: string, onTick: SubscribeBarsCallback,
                listenerGuid: string, onResetCacheNeededCallback: () => void): void {
    this.unsubscribeBars(listenerGuid);
    this.onReset = onResetCacheNeededCallback;

    const symbol = this.symbolStorage.findSymbolByNameSync(symbolInfo.name);

    const subscription = symbol.PriceChange
      .map(quote => this.quoteForTick(quote))
      .subscribe(bar => this.onTick(bar, resolution, onTick, symbol.SymbolName), (error: any) => this.logger.error(error));

    this.logger.debug('Sub to bars');

    this.subscriptionsMap.set(listenerGuid, subscription);
  }

  protected quoteForTick(quote: Quote): QuoteTick {
    let ask = null, bid = null;

    if (this.priceDirection === PriceDirection.Ask || this.priceDirection === PriceDirection.Any) {
      ask = quote.Ask;
    } else {
      bid = quote.Bid;
    }

    return new QuoteTick(ask, bid, quote.ServerTime);
  }

  protected async onTick(quote: QuoteTick, resolution: string, callback: SubscribeBarsCallback, symbolName: string) {
    try {
      const bar = await this.barAggregator.barTick(quote, resolution, symbolName);
      callback(bar);
    } catch (e) {
      console.log(e);
      this.logger.warn(e);
    }
  }

  unsubscribeBars(listenerGuid: string): void {
    const sub = this.subscriptionsMap.get(listenerGuid);

    if (sub) {
      this.logger.debug('Unsub from bars');

      sub.unsubscribe();
      this.subscriptionsMap.delete(listenerGuid);
    }
  }

  resetData(changeLanguage: boolean) {
    if (this.onReset) {
      this.onReset();
    }
    if (changeLanguage) {
      this.barAggregator.resetCurrent();
    }
    this.barAggregator.reset();
  }

  async onReady(callback: (configuration: DatafeedConfiguration) => void): Promise<void> {
    const result = this.configuration.getSettings();

    this.logger.debug('Ready');
    new Timer(() => callback(result), 0).start();
  }


  public get PriceDirection(): PriceDirection {
    return this.priceDirection;
  }

  public set PriceDirection(v: PriceDirection) {
    this.priceDirection = v;
  }

  private getBarInterval(time: number) {
    return Math.floor(time / this.interval);
  }

  public closeChartForMobile() {
    this.barAggregator.resetCurrent();
  }
}
