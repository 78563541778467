<div class="terminal-desktop">
  <div class="split-border header">
    <span class="header-logo" [ngClass]="getLogoCurrentThemeColor()"></span>

    <ul class="menu desktop-menu" [class.z-desktop]="IsZeTradex">
      <li class="menu-item sell-tab" id="user-menu-sell-tab" (click)="openSellModal($event)" *ngIf="!IsReadOnlyAccount">
        <span class="menu-icon icons-carbons menu-icon-sell" *ngIf="IsHiddenIconTopMenu"></span>
        <span class="menu-text"><l-unit [key]="'Shared_MenuComponent_desktop-menu-sell'"></l-unit></span>
      </li>

      <li class="menu-item buy-tab" id="user-menu-buy-tab" (click)="openBuyModal($event)" *ngIf="!IsReadOnlyAccount">
        <span class="menu-icon icons-carbons menu-icon-buy" *ngIf="IsHiddenIconTopMenu"></span>
        <span class="menu-text"><l-unit [key]="'Shared_MenuComponent_desktop-menu-buy'"></l-unit></span>
      </li>

      <li class="menu-item pending-tab" id="user-menu-pending-tab" (click)="openPendingOrderModal($event)" *ngIf="!IsReadOnlyAccount">
        <span class="menu-icon icons-carbons menu-icon-pending" *ngIf="IsHiddenIconTopMenu"></span>
        <span class="menu-text"><l-unit [key]="'Shared_MenuComponent_desktop-menu-pending'"></l-unit></span>
      </li>

      <li class="menu-item account-tab" id="user-menu-account-tab" (click)="openBackoffice($event, 'AccountPageUrl')">
        <span class="menu-icon icons-carbons menu-icon-account" *ngIf="IsHiddenIconTopMenu"></span>
        <span class="menu-text"><l-unit [key]="'Shared_MenuComponent_desktop-menu-account'"></l-unit></span>
      </li>

      <li class="menu-item settings-tab" id="user-menu-settings-tab" (click)="openSettingsModal($event)">
        <span class="menu-icon icons-carbons menu-icon-settings" *ngIf="IsHiddenIconTopMenu"></span>
        <span class="menu-text"><l-unit [key]="'Shared_MenuComponent_desktop-menu-settings'"></l-unit></span>
      </li>

      <li class="menu-item layout-settings layout-tab">
        <lib-layout-button class="menu-item" [hiddenIconTopMenu]="IsHiddenIconTopMenu" ></lib-layout-button>
      </li>

      <li class="menu-item save-view-menu layout-tab" *ngIf="IsViewsMenuAvailable">
        <span class="menu-icon icons-carbons menu-icon-saved-views" *ngIf="IsHiddenIconTopMenu"></span>
        <lib-saved-views class="menu-item"></lib-saved-views>
      </li>

      <li class="menu-item settings-tab" *ngIf="IsUrlPathDeposit && !IsFXLiveMarketsTrader" (click)="topUpYourDeposit()">
        <span class="menu-icon icons-carbons menu-icon-deposit" *ngIf="IsHiddenIconTopMenu"></span>
        <span class="menu-text"><l-unit [key]="'Shared_MenuComponent_desktop-menu-deposit'"></l-unit></span>
      </li>

      <li class="menu-item account-tab" *ngIf="ShowButtonBOAnalytics" (click)="openBackoffice($event, 'BOAnalyticsUrl')">
        <span class="menu-icon menu-icon-analytics" *ngIf="IsHiddenIconTopMenu"></span>
        <span class="menu-text"><l-unit [key]="'Shared_MenuComponent_BOAnalytics'"></l-unit></span>
      </li>

      <li class="layout-tab" *ngIf="ShowButtonModeDebugUsers">
        <button class="button-danger button-mode-debug" (click)="ShowWindowModeDebugUsers()">
          <l-unit [key]="'TerminalModule_UserMenuComponent_ModeDebug'"></l-unit>
        </button>
      </li>

    </ul>

    <div class="user-block menu">
      <span class="menu-item settings-tab" *ngIf="IsUrlPathDeposit && IsFXLiveMarketsTrader">
        <button class="button-success-blue-color" (click)="topUpYourDeposit()">
          <l-unit [upperCase]="true" [key]="'Shared_MenuComponent_desktop-menu-deposit'"></l-unit>
        </button>
      </span>
      <span [class.save-view-menu]="!IsZeTradex" *ngIf="ShowButtonTheme">
        <button class="button-theme" (click)="changeThemeOrShowOptions()" >
          <span class="theme-icon"></span>
        </button>
        <div class="vertical-menu user-vertical-menu theme-menu dropdown dropdown-change-theme" *ngIf="!IsZeTradex">
          <a (click)="changeTheme('tvDarkTheme')"><l-unit [key]="'TerminalModule_UserMenuComponent_dark-theme'"></l-unit></a>
          <a (click)="changeTheme('tvLightTheme')"><l-unit [key]="'TerminalModule_UserMenuComponent_light-theme'"></l-unit></a>
          <!--        <a (click)="changeTheme('tvDefaultTheme')"><l-unit [key]="'TerminalModule_UserMenuComponent_default-theme'"></l-unit></a>-->
        </div>
      </span>
      <app-notification-tape></app-notification-tape>
      <div class="user-menu save-view-menu user-menu-box">

        <div>
          <span class="user-welcome-text">
          <l-unit [key]="'TerminalModule_UserMenuComponent_user-welcome-text'"></l-unit> ,
          </span>
          <a class="user-profile-link">
            {{ TraderName }}
          </a>
          <span class="user-welcome-text" *ngIf="IsReadOnlyAccount">[<l-unit [key]="'TerminalModule_UserMenuComponent_ReadOnly'"></l-unit>]</span>
          <span class="user-profile-dropdown"></span>
        </div>

        <span class="user-welcome-text user-welcome-text-title-position" *ngIf="ShowTradeGroupName">
          <l-unit [key]="'TerminalModule_UserMenuComponent_trade-group-name'"></l-unit> :
          <span class="user-welcome-text-title"> {{ TradeGroupName }} </span>
        </span>

        <div class="vertical-menu user-vertical-menu dropdown" [class.z-vertical-menu]="IsZeTradex">
          <a (click)="signOut($event)">
            <l-unit [key]="'TerminalModule_UserMenuComponent_sign-out'"></l-unit>
          </a>
          <a (click)="changePassword($event)" *ngIf="IsShowChangePassword">
            <l-unit [key]="'TerminalModule_UserMenuComponent_change-password'"></l-unit>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="message-container" *ngIf="showMessage()" (click)="onCloseMessageClick()">
    <div class="message-position-box">
      <div class="message-box" (click)="stopPropagationForMessageChallenge($event)">
        <span class="remove-message-icon" (click)="onCloseMessageClick()">&#10006;</span>
        <span class="notification-header" [textContent]="Header"></span>
        <span class="notification-body" *ngFor="let text of Body"><span [textContent]="text"></span></span>
        <span class="notification-time"><l-date [date]="Date"></l-date></span>
      </div>
    </div>
  </div>

  <div class="message-container" *ngIf="ShowNotificationCheckConnection">
    <div class="message-position-box">
      <div class="message-box check-connection-box">
        <img class="spinner-img" ngSrc="assets/img/spinner.svg" width="32" height="32">
        <span class="notification-header"><l-unit [key]="'TerminalModule_UserMenuComponent_CheckingConnectionToServer'"></l-unit></span>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>

  <app-footer class="split-border"></app-footer>

  <router-outlet name="modal"></router-outlet>

  <mode-debug-users class="position-mode-debug-users-container" *ngIf="IsShowWindowModeDebugUsers"></mode-debug-users>

  <app-state-server-connection></app-state-server-connection>

</div>
