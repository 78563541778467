import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {Trader} from '@common/trader/models/trader';
import {BaseGuard} from '@common/routing/guards/base.guard';

@Injectable({
  providedIn: 'root'
})
export class IsLoginGuard  {
  public constructor(private trader: Trader, private router: Router) {}

  private check(): boolean {
    if (!this.trader.IsLoggedIn) {
      this.router.navigate(['/sign-in']).then();
      return false;
    }

    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.trader.urlToOpenAfterLogin = BaseGuard.getResolvedUrl(route);
    return this.check();
  }
}
