import {isPendingTrade, TradeType} from './trade-type';
import {Symbol} from '@common/symbol/models/symbol';
import {TradePriceProvider} from '../utils/price-provider/trade-price-provider';
import {Trade} from './trade';
import {DateField} from '../../common/models/date-field';
import {Settings} from '@common/trader/models/settings';
import {CustomNumber} from '@common/trade/utils/custom-number';

export class TradeDTO {
  private tradeId: number;
  private type: TradeType;
  protected stopLoss = 0;
  protected takeProfit = 0;
  protected trailingStop = 0;
  protected volume = 0;
  protected openPrice = 0;
  private symbol: Symbol;
  private isClosed: boolean;
  private expirationDate: DateField;
  private comment = '';
  private margin = 0;
  private commandVolumeUSD = 0;
  private commandVolumeAC = 0;
  private volumeUSD = 0;
  private volumeAC = 0;
  private _useBreakEven = false;


  get UseBreakEven(): boolean {
    return this._useBreakEven;
  }

  set UseBreakEven(value: boolean) {
    this._useBreakEven = value;
  }

  public constructor() {
    this.expirationDate = new DateField();
  }

  public get IsPending(): boolean {
    return isPendingTrade(this.Type);
  }

  get TradeId(): number {
    return this.tradeId;
  }

  set TradeId(value: number) {
    this.tradeId = value;
  }

  get IsClosed(): boolean {
    return this.isClosed;
  }

  set IsClosed(value: boolean) {
    this.isClosed = value;
  }

  public get Type(): TradeType {
    return this.type;
  }
  public set Type(v: TradeType) {
    this.type = v;
  }

  public get OpenPrice(): number {
    return CustomNumber.cutNumber(this.openPrice, this.Symbol.DecimalPlaces);
  }

  public NoCutOpenPrice(): number {
    return this.openPrice;
  }

  public set OpenPrice(v: number) {
    this.openPrice = Number(v);
  }

  public get Volume(): number {
    return this.volume;
  }
  public set Volume(v: number) {
    this.volume = v;
  }

  public get CommandVolumeUSD(): number {
    return this.commandVolumeUSD;
  }
  public set CommandVolumeUSD(v: number) {
    this.commandVolumeUSD = v;
  }

  public get CommandVolumeAC(): number {
    return this.commandVolumeAC;
  }
  public set CommandVolumeAC(v: number) {
    this.commandVolumeAC = v;
  }

  public get VolumeUSD(): number {
    return this.volumeUSD;
  }
  public set VolumeUSD(v: number) {
    this.volumeUSD = v;
  }

  public get VolumeAC(): number {
    return this.volumeAC;
  }
  public set VolumeAC(v: number) {
    this.volumeAC = v;
  }


  public get Margin(): number {
    return this.margin;
  }
  public set Margin(v: number) {
    this.margin = v;
  }

  public get Symbol(): Symbol {
    return this.symbol;
  }
  public set Symbol(v: Symbol) {
    this.symbol = v;
  }
  public get SymbolName(): string {
    return this.symbol.SymbolName;
  }

  public get CurrentPips(): number {
    return 0;
  }
  public get CurrentPrice(): number {
    if (isPendingTrade(this.Type)) {
      return this.openPrice;
    } else {
      return this.CurrentRate;
    }
  }
  public get CurrentRate(): number {
    if (this.tradeId) {
      return TradePriceProvider.getTradeClosingPrice(this.Symbol, this.Type);
    } else {
      return TradePriceProvider.getTradeCurrentPrice(this.Symbol, this.Type);
    }
  }

  public get TakeProfit(): number {
    return this.takeProfit;
  }
  public set TakeProfit(v: number) {
    this.takeProfit = v;
  }

  public get TrailingStop(): number {
    return this.trailingStop;
  }
  public set TrailingStop(v: number) {
    this.trailingStop = Number(v);
  }

  public get StopLoss(): number {
    return this.stopLoss;
  }
  public set StopLoss(v: number) {
    this.stopLoss = v;
  }

  public get Comment(): string {
    return this.comment;
  }
  public set Comment(value: string) {
    this.comment = value;
  }

  public get ExpirationDate(): DateField {
    return this.expirationDate;
  }

  public readFromTrade(trade: Trade): TradeDTO {
    console.log('1:', trade);
    this.Volume = trade.Volume;
    this.StopLoss = trade.StopLoss;
    this.TakeProfit = trade.TakeProfit;
    this.TrailingStop = trade.TrailingStop;
    this.OpenPrice = trade.OpenPrice;
    this.IsClosed = trade.IsClosed;
    this.expirationDate.Date = trade.ExpirationDate;
    this.Symbol = trade.Symbol;
    this.Type = trade.Type;
    this.TradeId = trade.TradeId;
    this.Comment = trade.Comment;
    this.Margin = trade.Margin;
    this.CommandVolumeUSD = trade.CommandVolumeUSD;
    this.CommandVolumeAC = trade.CommandVolumeAC;
    this.VolumeUSD = trade.VolumeUSD;
    this.VolumeAC = trade.VolumeAC;
    return this;
  }

  public readFromSettings(settings: Settings): TradeDTO {
    this.TakeProfit = settings.OneClickTP;
    this.StopLoss = settings.OneClickSL;
    this.Volume = settings.OneClickAmount;

    return this;
  }

  public deepCopy(): this {
    const result = Object.assign(new TradeDTO(), this);

    return result;
  }
}
